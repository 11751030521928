@import "../../../styles/variables";
@import "../../../styles/mixins";

.button {
  border-radius: 30px;
  line-height: 45px;
  font-size: 1.35rem;
  text-align: center;
  width: 47vw;
  max-width: 250px;
  background: $mainColor;
  color: $white;
  display: block;
  margin: 0 auto 6vh auto;
  transition: all .2s;
  &:hover {
    background: $mainColor; }
  &:disabled {
    background: $mainColorTransparent; }
  @include mw($minXXS) {
    font-size: 1.45rem;
    line-height: 47px; }
  @include mw($minMD) {
    font-size: 1.5rem;
    line-height: 50px; }
  @include mw($minLG) {
    font-size: 1.8rem;
    line-height: 55px; }
  @include mw($minXXL) {
    font-size: 1.85rem;
    line-height: 60px; } }
