@import "../../styles/variables";
@import "../../styles/mixins";

.quiz {
  padding: 13vh 0 8vh 0;
  height: 100%;
  min-height: 100vh;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > form {
    width: 80%;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto; }
  &__desc {
    font-family: SanchezFolksamMedium, serif;
    color: $mainLight;
    margin-bottom: 2vh;
    font-size: 1.05em;
    @include mw($minMD) {
      font-size: 1.1em; }
    @include mw($minXXL) {
      font-size: 1.2em; } }
  &__question {
    margin-bottom: 4vh;
    font-size: 1.5em;
    line-height: 1.25;
    @include mw($minLG) {
      font-size: 1.6em; }
    @include mw($minXXL) {
      font-size: 1.7em; } }
  & button:last-of-type {
    margin-top: 4.5vh; } }
