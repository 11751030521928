@import "../../styles/variables";
@import "../../styles/mixins";

.start {
  padding: 13vh 0 8vh 0;
  height: 100%;
  font-size: 14px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  &__logo {
    width: 63%;
    max-width: 280px;
    position: relative;
    margin: 0 auto 6vh auto;
    & > img {
      width: 100%;
      height: auto;
      object-fit: contain; }
    & img:last-of-type {
      position: absolute;
      bottom: -18px;
      left: 50%;
      transform: translateX(-50%);
      width: 66%;
      height: auto;
      object-fit: contain; } }
  &__text {
    width: 64%;
    max-width: 300px;
    text-align: left;
    font-size: 1em;
    line-height: 1.5;
    margin-bottom: 6vh;
    margin-left: auto;
    margin-right: auto;
    @include mw($minXS) {
      font-size: 1.05em; }
    @include mw($minMD) {
      font-size: 1.1em; }
    @include mw($minLG) {
      font-size: 1.25em; } } }
