@import "../../styles/variables";
@import "../../styles/mixins";

.result {
  padding: 13vh 0 8vh 0;
  height: 100%;
  min-height: 100vh;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  &__content > img {
    margin-bottom: 3.4vh; }
  h1 {
    color: $mainLight;
    font-size: 3em;
    margin-bottom: 3vh;
    @include mw($minMD) {
      font-size: 3.5em; }
    @include mw($minLG) {
      font-size: 3.7em; } }
  &__text {
    font-family: SanchezFolksamMedium, serif;
    margin-bottom: 3.4vh;
    font-size: 1.7em;
    @include mw($minMD) {
      font-size: 1.9em; }
    @include mw($minLG) {
      font-size: 2.05em; }
    span:first-of-type {
      margin-right: 5px; }
    span:last-of-type {
      margin-left: 5px; }
    &:last-of-type {
      margin-bottom: 5vh; } }
  &__amount {
    color: $mainLight;
    font-family: SanchezFolksamBlack, serif;
    font-size: 2em;
    vertical-align: middle; } }
