// media queries
// https://gs.statcounter.com/screen-resolution-stats/mobile/worldwide
$mobileContent: 915px;
$minXXL: 768;
$minLG: 576;
$minMD: 412;
$minXS: 390;
$minXXS: 375;

// colors
$lightBg: #E9F8FF;
$mainBg: #D2F1FE;
$mainColor: #003E80;
$mainColorTransparent: rgba(0, 62, 128, .7);
$mainLight: #009CE0;
$white: white;
$errorColor: #C60000;
$pink: #F09EC8;

// fonts
$fontMain: 'Sanchez', serif;

// z-index
$base: 1000;
$above: 10; // use this for all values above the base
$below: -10; // and this for all values below the base
