* {
	padding: 0;
	margin: 0;
	border: 0;
	box-sizing: border-box; }
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box; }
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block; }
ol, ul {
	list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=); }
blockquote, q {
	quotes: none; }
blockquote:before, blockquote:after, q:before, q:after {
	content: none; }
table {
	border-collapse: collapse;
	border-spacing: 0; }
*:before, *:after {
	box-sizing: border-box; }
*:focus, *:active {
	outline: none; }
a:focus, a:active {
	outline: none; }
html, body {
	height: 100%;
	width: 100%;
	line-height: 1;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%; }
input, button, textarea {
	font-family: inherit; }
input::-ms-clear {
	display: none; }
button {
	cursor: pointer;
	outline: none; }
button::-moz-focus-inner {
	padding: 0;
	border: 0; }
a, a:visited, a:hover {
	text-decoration: none; }
ul li {
	list-style: none; }
img {
	vertical-align: top; }
h1, h2, h3, h4, h5, h6 {
	font-size: inherit;
	font-weight: inherit; }
