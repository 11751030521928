@import "../../styles/variables";
@import "../../styles/mixins";

.answer {
  width: 100%;
  background: transparent;
  margin-bottom: 2vh;
  &__variant {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: $pink;
    color: $white;
    border-radius: 15px;
    min-height: 9vh;
    & > span {
      flex-grow: 0;
      min-width: 6.5%;
      border-bottom-left-radius: 15px;
      border-top-left-radius: 15px;
      line-height: 100%;
      font-size: 1.2em;
      @include mw($minXXL) {
        font-size: 1.3em; } } }
  &__text {
    min-height: 9vh;
    flex-grow: 1;
    background-color: $white;
    border-bottom-right-radius: 15px;
    text-align: left;
    display: flex;
    align-items: center;
    border-top-right-radius: 15px;
    height: 100%;
    color: $mainColor;
    font-family: SanchezFolksamMedium, serif;
    padding: 13px;
    font-size: 1.35em;
    line-height: 1.1;
    & > span {
      display: block; }
    @include mw($minXXL) {
      font-size: 1.4em; } }
  &__text-chosen {
    min-height: 9vh;
    background-color: $mainColor;
    flex-grow: 1;
    border-bottom-right-radius: 15px;
    text-align: left;
    display: flex;
    align-items: center;
    border-top-right-radius: 15px;
    height: 100%;
    color: $white;
    font-family: SanchezFolksamMedium, serif;
    padding: 13px;
    font-size: 1.35em;
    line-height: 1.1;
    & > span {
      display: block; }
    @include mw($minXXL) {
      font-size: 1.4em; } } }
