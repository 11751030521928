@import "./variables";
@import "./mixins";
@import "./reset";

@font-face {
  font-family: 'SanchezFolksamBold';
  src: url(../assets/fonts/SanchezFolksamBold.eot);
  src: url(../assets/fonts/SanchezFolksamBold.eot?#iefix) format('embedded-opentype'), url(../assets/fonts/SanchezFolksamBold.svg#SanchezFolksamBold) format('svg'), url(../assets/fonts/SanchezFolksamBold.ttf) format('truetype'), url(../assets/fonts/SanchezFolksamBold.woff) format('woff'), url(../assets/fonts/SanchezFolksamBold.woff2) format('woff2');
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: 'SanchezFolksamMedium';
  src: url(../assets/fonts/SanchezFolksamMedium.eot);
  src: url(../assets/fonts/SanchezFolksamMedium.eot?#iefix) format('embedded-opentype'), url(../assets/fonts/SanchezFolksamMedium.svg#SanchezFolksamMedium) format('svg'), url(../assets/fonts/SanchezFolksamMedium.ttf) format('truetype'), url(../assets/fonts/SanchezFolksamMedium.woff) format('woff'), url(../assets/fonts/SanchezFolksamMedium.woff2) format('woff2');
  font-style: normal;
  font-weight: 500; }

@font-face {
  font-family: 'SanchezFolksamBlack';
  src: url(../assets/fonts/SanchezFolksamBlack.eot);
  src: url(../assets/fonts/SanchezFolksamBlack.eot?#iefix) format('embedded-opentype'), url(../assets/fonts/SanchezFolksamBlack.svg#SanchezFolksamBlack) format('svg'), url(../assets/fonts/SanchezFolksamBlack.ttf) format('truetype'), url(../assets/fonts/SanchezFolksamBlack.woff) format('woff'), url(../assets/fonts/SanchezFolksamBlack.woff2) format('woff2');
  font-style: normal;
  font-weight: 900; }

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $lightBg; }

img {
  image-rendering: -webkit-optimize-contrast;
  object-fit: cover; }

#root {
  height: 100%;
  color: $mainColor;
  font-family: SanchezFolksamBold, serif; }

main {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  background-color: $mainBg;
  max-width: $mobileContent; }

.logo {
  width: 63vw;
  height: auto;
  max-width: 280px;
  margin-bottom: 6vh;
  object-fit: contain; }

.logo-folksam {
  width: 43%;
  height: auto;
  max-width: 220px;
  object-fit: contain;
  display: inline-block;
  margin: 1vh auto 0 auto;
  flex-shrink: 0; }

input {
  color: $mainColor;
  font-family: SanchezFolksamMedium, serif;
  font-size: 0.9em;
  @include mw($minXXS) {
    font-size: 1em; }
  @include mw($minXS) {
    font-size: 1.1em; } }

input[type="checkbox"] {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transition: box-shadow .2s;
  background: $white;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
    border: 2px solid $mainColor; } }

input[type="checkbox"]:checked {
  box-shadow: inset 0 0 0 9px $mainColor;
  border: 2px solid $white; }

textarea {
  width: 100%;
  resize: none;
  color: $mainColor;
  line-height: 1.5;
  font-family: SanchezFolksamMedium, serif;
  padding: 5px 10px;
  @include mw($minXS) {
    font-size: 1.05em; }
  @include mw($minMD) {
    font-size: 1.1em; }
  @include mw($minLG) {
    font-size: 1.3em; }
  &::-webkit-scrollbar {
    width: 4px;
    background-color: $lightBg; }
  &::-webkit-scrollbar-track {
    border-radius: 2px; }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $mainColor; } }

.error {
  color: $errorColor;
  min-height: 16px;
  margin-bottom: 10px;
  font-size: 0.75em;
  line-height: 1.25;
  @include mw($minXXS) {
    font-size: 0.85em; }
  @include mw($minXS) {
    font-size: 0.9em; }
  @include mw($minLG) {
    font-size: 0.95em; } }

.form-group {
  @include mw($minLG) {
    justify-content: space-between;
    display: flex;
    align-items: center; }
  & p {
    margin-right: 10px;
    flex-shrink: 0;
    font-size: 0.95rem;
    line-height: 1.25;
    margin-bottom: 3px;
    @include mw($minXS) {
      font-size: 1rem; }
    @include mw($minMD) {
      font-size: 1.05rem; }
    @include mw($minLG) {
      font-size: 0.95rem;
      margin-bottom: 0; } }
  & input {
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    @include mw($minMD) {
      font-size: 1.1rem; }
    @include mw($minLG) {
      display: block;
      flex-shrink: 1;
      font-size: 1rem; }
    @include mw($minXXL) {
      font-size: 1.1rem; } } }
