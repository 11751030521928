@import "../../styles/variables";
@import "../../styles/mixins";

.auth {
  text-align: center;
  height: 100%;
  min-height: 100vh;
  font-size: 14px;
  padding: 13vh 0 8vh 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & img:first-of-type {
    margin-bottom: 40px; }
  &__content {
    text-align: left;
    width: 64%;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    & h1 {
      font-size: 1.2rem;
      text-align: center;
      margin-bottom: 25px;
      @include mw($minXXS) {
        font-size: 1.35rem; }
      @include mw($minMD) {
        font-size: 1.5rem; }
      @include mw($minLG) {
        font-size: 1.8rem; }
      @include mw($minXXL) {
        font-size: 1.85rem; } } }
  &__text {
    margin-bottom: 25px;
    font-size: 0.95rem;
    line-height: 1.25;
    @include mw($minXS) {
      font-size: 1rem; }
    @include mw($minMD) {
      font-size: 1.05rem; }
    @include mw($minLG) {
      font-size: 1.25rem; }
    @include mw($minXXL) {
      font-size: 1.3rem; } }
  &__input {
    &:-webkit-autofill {
      -webkit-text-fill-color: $mainColor;
      transition: background-color 5000s ease-in-out 0s; }
    &:-webkit-autofill:hover {
      -webkit-text-fill-color: $mainColor;
      transition: background-color 5000s ease-in-out 0s; }
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: $mainColor;
      transition: background-color 5000s ease-in-out 0s; } }
  &__agreement {
    display: flex; }
  &__label {
    cursor: pointer;
    flex-shrink: 1;
    font-size: 0.7em;
    line-height: 1.25;
    @include mw($minXXS) {
      font-size: 0.75em; }
    @include mw($minXS) {
      font-size: 0.85em; }
    @include mw($minLG) {
      font-size: 1.1em; } }
  &__checkbox {
    margin-right: 15px;
    flex-shrink: 0;
    display: block; } }
